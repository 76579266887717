<template>
  <div class="catalogs d-flex">
    <v-navigation-drawer>
      <template #prepend>
        <v-list-item two-line>
          Справочники
        </v-list-item>
      </template>

      <v-divider />

      <v-list dense>
        <template v-for="(catalog, idx) in catalogs">
          <v-list-item
            v-if="catalog !== null"
            :key="catalog.value"
            :class="isActive(catalog)"
            @click="select(catalog.value)"
          >
            <v-list-item-content class="pointer">
              <v-list-item-title>{{ catalog.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-else
            :key="`divider-${idx}`"
            class="my-1"
          />
        </template>
      </v-list>
    </v-navigation-drawer>
    <div class="catalogs__body">
      <div
        v-show="!activeComponent"
        class="noComponent"
      >
        <p>
          Выберите справочник чтобы продолжить
        </p>
      </div>
      <component
        :is="activeComponent"
        :params="params"
        @perform="onPerform"
      />
    </div>
  </div>
</template>
<script>

export default {
  data:() => ({
    activeComponent:null,
    params:{
      item:null,
    },
    catalogs:[
      {
        name:'Транспорт',
        value:'vehicles',
        component:() => import('@/components/catalogs/vehicles.vue')
      },
      {
        name:'Перевозчики',
        value:'carriers',
        component:() => import('@/components/catalogs/carriers.vue')
      },
      {
        name:'Водители',
        value:'drivers',
        component:() => import('@/components/catalogs/drivers.vue')
      },
      {
        name:'Резиденты',
        value:'residents',
        component:() => import('@/components/catalogs/regions.vue')
      },
      {
        name:'Нерезиденты',
        value:'noResidents',
        component:() => import('@/components/catalogs/no-residents.vue')
      },
      {
        name:'Клиенты СО',
        value:'clientsSo',
        component:() => import('@/components/catalogs/clients-so.vue')
      },
      null,
      {
        name:'Страны',
        value:'countries',
        component:() => import('@/components/catalogs/countries.vue')
      },
      {
        name:'Регионы',
        value:'regions',
        component:() => import('@/components/catalogs/regions.vue')
      },
      {
        name:'Валюта',
        value:'currencies',
        component:() => import('@/components/catalogs/currencies.vue')
      },
      {
        name:'Типы сделки',
        value:'dealTypes',
        component:() => import('@/components/catalogs/deal-types.vue')
      },
      {
        name:'Документы',
        value:'documentTypes',
        component:() => import('@/components/catalogs/document-types.vue')
      },
      {
        name: 'Бренды',
        value:'brands',
        component:() => import('@/components/catalogs/brands.vue')
      }
    ],
    forms:[
      {
        value:'residentsForm',
        component:() => import('@/components/catalogs/forms/residents-form.vue')
      },
      {
        value:'noResidentsForm',
        component:() => import('@/components/catalogs/forms/no-residents-form.vue')
      },
      {
        value:'vehiclesForm',
        component:() => import('@/components/catalogs/forms/vehicles-form.vue')
      },
      {
        value:'carriersForm',
        component:() => import('@/components/catalogs/forms/carriers-form.vue')
      },
      {
        value:'clientsSoForm',
        component:() => import('@/components/catalogs/forms/clients-so-form.vue')
      },
      {
        value:'driversForm',
        component:() => import('@/components/catalogs/forms/drivers-form.vue')
      },
    ]
  }),
  created(){
    this.$store.dispatch('catalogs/fetchAll')
    this.openDefaultComponent()
  },
  methods:{
    isActive(item){
      return item.value === this.activeComponentName ? 'teal lighten-1': ''
    },
    openDefaultComponent(){
      const q = this.$route.query
      if(Object.keys(q).length && q?.list){
        this.select(q.list)
      }
    },
    select(code){
      const {component, value} = this.catalogs.find(i => i?.value === code)
      this.activeComponentName = value
      this.activeComponent = component ?? null
    },
    onPerform({component:code, item = null}){
      item ? this.params.item = item : this.params.item = null
      const {component} = this.forms.find(i => i.value === code)

      this.activeComponent = component ?? null
    }
  }
}
</script>
<style scoped>
    .pointer{
        cursor: pointer;
    }
  .catalogs{
    width:100%;
    min-height: calc(100vh - 129px)
  }
  .catalogs__body{
    width: 100%;
  }
  .noComponent{
    width: 100%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
  }


</style>
